import { MediaTypes } from '../../architecture/interfaces/IMediaAttachment';
import { AudioAttachment } from './AudioAttachment';
import { DocumentAttachment } from './DocumentAttachment';
import { ImageAttachment } from './ImageAttachment';
import { VideoAttachment } from './VideoAttachment';
export class MediaProcessor {
    constructor(paragraph, attachments, settings) {
        this.attachments = [];
        const container = document.createElement('div');
        container.id = 'lailo-media-container';
        this.container = container;
        this.attachments = attachments.map((attachment) => this.createAttachmentInstance(attachment, settings));
        this.attachments.forEach((attachment) => this.appendAttachment(attachment));
        this.injectIntoAnswer(paragraph);
    }
    createAttachmentInstance(attachment, settings) {
        const mediaType = (() => {
            const isDocument = DocumentAttachment.COMMON_DOCUMENT_TYPES.some((item) => attachment.contentType.includes(item));
            if (isDocument)
                return MediaTypes.Document;
            if (attachment.contentType.includes('video'))
                return MediaTypes.Video;
            if (attachment.contentType.includes('audio'))
                return MediaTypes.Audio;
            return MediaTypes.Image;
        })();
        switch (mediaType) {
            case MediaTypes.Video:
                return new VideoAttachment(attachment, settings);
            case MediaTypes.Document:
                return new DocumentAttachment(attachment, settings);
            case MediaTypes.Audio:
                return new AudioAttachment(attachment, settings);
            case MediaTypes.Image:
            default:
                return new ImageAttachment(attachment, settings);
        }
    }
    appendAttachment(attachment) {
        this.container.appendChild(attachment.mainContainer);
    }
    injectIntoAnswer(paragraph) {
        paragraph.appendChild(this.container);
    }
}
