var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Utilities } from '../../utilities/Utilities';
import htmlTemplate from '../../templates/privacy/privacy.html';
export class LailoPrivacy {
    constructor(renderContainer, texts, canMount, onMount) {
        this.isMounted = false;
        this.isSubmitting = false;
        this.preservedContainers = [];
        this.callbacks = {
            onAccepted: [],
            onDeclined: [],
            onUnmount: [],
        };
        this.canMount = canMount;
        this.renderContainer = renderContainer;
        this.texts = texts;
        this.template = Utilities.parseTemplate(htmlTemplate.trim());
        this.preserveContainerContent(this.renderContainer);
        this.queryElements();
        this.mount(onMount);
    }
    get isChecked() {
        var _a;
        return !!((_a = this.checkbox) === null || _a === void 0 ? void 0 : _a.checked);
    }
    subscribe(event, callback) {
        this.callbacks[event].push(callback);
    }
    acceptConsent() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.canMount || !this.isChecked)
                return;
            this.isSubmitting = true;
            this.submitButton.dataset.lailoDisabled = "true";
            yield window.character.initCommunication();
            this.isSubmitting = false;
            this.callbacks.onAccepted.forEach((callback) => callback());
            this.unmount();
        });
    }
    /**
     * Preserves the children of an HTML Container and removes them from the DOM.
     * Once the Privacy Container unmounts, the preserved children will be re-injected into the container.
     * @param container An HTML Element that functions as a container
     * @returns void
     */
    disableContainer(container) {
        if (!this.canMount)
            return;
        this.preserveContainerContent(container);
        this.removeContainerContent(container);
    }
    mount(onMount = () => { }) {
        if (!this.canMount)
            return;
        const parentWidth = this.renderContainer.scrollWidth;
        this.template.style.width = parentWidth + 'px';
        this.preservedContainers.forEach((item) => this.removeContainerContent(item.container));
        this.renderContainer.appendChild(this.template);
        this.isMounted = true;
        onMount();
    }
    removeContainerContent(container) {
        Array.from(container.children).forEach((child) => child.remove());
    }
    preserveContainerContent(element) {
        // Children is very important! ChildNodes would track all the changes that are happening to the children of an HTMLElement
        // But we need a snapshot of what was inside before we replaced it with the privacy container
        // InnerHTML would be a bad idea because all event listeners would be removed
        this.preservedContainers.push({
            container: element,
            children: Array.from(element.children),
        });
    }
    queryElements() {
        this.title = this.template.querySelector('#lailo-privacy-container-title');
        this.description = this.template.querySelector('#lailo-privacy-container-description');
        this.form = this.template.querySelector('#lailo-privacy-form');
        this.checkbox = this.template.querySelector('#lailo-privacy-checkbox');
        this.checkBoxLabel = this.template.querySelector('#lailo-privacy-checkbox-label');
        this.submitButton = this.template.querySelector('#lailo-privacy-submit-button');
        this.setupTexts();
        this.attachEventListeners();
        // this.description!.dataset.simplebar = 'true';
    }
    setupTexts() {
        this.title.textContent = this.texts.title;
        this.description.innerHTML = Utilities.purifyHtml(this.texts.description);
        this.checkBoxLabel.textContent = this.texts.checkBoxLabel;
        this.submitButton.textContent = this.texts.buttonLabel;
    }
    attachEventListeners() {
        var _a, _b, _c;
        (_a = this.form) === null || _a === void 0 ? void 0 : _a.addEventListener('submit', (e) => e.preventDefault());
        (_b = this.checkbox) === null || _b === void 0 ? void 0 : _b.addEventListener('change', () => {
            if (this.isSubmitting)
                return;
            this.submitButton.dataset.lailoDisabled = String(!this.checkbox.checked);
        });
        (_c = this.submitButton) === null || _c === void 0 ? void 0 : _c.addEventListener('click', (e) => {
            e.preventDefault();
            this.acceptConsent();
        });
    }
    unmount() {
        this.template.remove();
        this.restorePreservedContent();
        this.callbacks.onUnmount.forEach((callback) => callback());
        this.isMounted = false;
    }
    restorePreservedContent() {
        this.preservedContainers.forEach(({ container, children }) => {
            children.forEach((child) => container.appendChild(child));
        });
    }
}
