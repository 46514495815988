import htmlTemplate from '../../templates/media/video.html';
import { Utilities } from '../../utilities/Utilities';
import { MediaAttachment } from './MediaAttachment';
export class VideoAttachment extends MediaAttachment {
    constructor(source, externalSettings) {
        super(source, externalSettings);
        this.breadcrumb = {};
        this.mainContainer = Utilities.parseTemplate(htmlTemplate.trim());
        this.setupFullscreenContainer();
        this.queryElements();
        this.attachEventListeners();
        this.applyExternalConfig();
    }
    attachEventListeners() {
        this.mainContainer.addEventListener('click', () => {
            document.body.prepend(this.fullscreenContainer);
        });
    }
    attachSource() {
        this.preview.src = this.source.contentUrl;
        this.preview.autoplay = true;
        this.preview.muted = true;
        this.preview.loop = true;
        this.preview.ontimeupdate = (e) => {
            this.breadcrumb.text.textContent = this.createBreadcrumbText(this.preview.currentTime);
        };
    }
    createBreadcrumbText(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
    }
    queryElements() {
        this.preview = this.mainContainer.querySelector('#lailo-video-element');
        this.breadcrumb.text = this.mainContainer.querySelector('#lailo-video-breadcrumb-text');
        this.breadcrumb.icon = this.mainContainer.querySelector('#lailo-video-breadcrumb-icon');
        this.attachSource();
    }
    setupFullscreenContainer() {
        const sourceElement = document.createElement('source');
        sourceElement.src = this.source.contentUrl;
        sourceElement.type = this.source.contentType;
        const fullScreenVideoElement = document.createElement('video');
        fullScreenVideoElement.id = 'lailo-fullscreen-video';
        fullScreenVideoElement.controls = true;
        fullScreenVideoElement.append(sourceElement);
        const container = this.createFullScreenContainer();
        container.appendChild(fullScreenVideoElement);
        this.fullscreenContainer = container;
    }
}
