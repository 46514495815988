import DOMPurify from 'dompurify';
import { InlineLinkTypes } from '../architecture/enums/InlineLinks';
export class Utilities {
    static sanitizeJSON(text) {
        return text.replace(/\r\n|\n|\r|\t|\s|[\u00a0]/gm, '').replace(/[ ]*"/g, '"');
    }
    static isJsonValid(body) {
        if (!body)
            return false;
        try {
            const sanitizedBody = JSON.stringify(this.sanitizeJSON(body));
            JSON.parse(JSON.parse(sanitizedBody));
            return true;
        }
        catch (error) {
            return false;
        }
    }
    static isEmpty(input) {
        if (input === null || input === undefined)
            return true;
        // Indicates that the input might be something like "{}"
        if (typeof input === 'string' && this.isJsonValid(input)) {
            try {
                if (typeof JSON.parse(input) != 'object')
                    return false;
                return Object.keys(JSON.parse(input)).length === 0;
            }
            catch (error) {
                return false;
            }
        }
        if (typeof input === 'object' && !Array.isArray(input)) {
            return Object.keys(input).length === 0;
        }
        if (Array.isArray(input) || typeof input === 'string') {
            return input.length === 0;
        }
        return false;
    }
    static isEmailValid(input) {
        if (!input)
            return false;
        return !!input.match(this.regex.Email);
    }
    static areArraysEqual(arr1, arr2) {
        if (arr1.length !== arr2.length)
            return false;
        return arr1.every((item, index) => arr2[index] === item);
    }
    static replaceCharacterInText(text, charToReplace, replacer) {
        const regexp = new RegExp(charToReplace, 'g');
        return text.replace(regexp, replacer);
    }
    static replaceAllSpecialCharactersInText({ text, replacer = '_', exceptions = ['_', '.'], }) {
        const regexp = new RegExp(/[!@°§#$%^&*()+\-=\[\]{};':"\\|,._<>\/?\s]+/);
        let characters = text.split('');
        characters = characters.map((x) => {
            if (exceptions.includes(x))
                return x;
            return regexp.test(x) ? replacer : x;
        });
        return characters.join('');
    }
    static truncateText(text, limiter) {
        if (text.length <= limiter)
            return text;
        let truncatePoint = limiter - 3;
        const regex = /[\s,.;:]/;
        if (!regex.test(text[truncatePoint])) {
            let char = text[truncatePoint];
            while (!regex.test(char)) {
                truncatePoint -= 1;
                char = text[truncatePoint];
                if (truncatePoint === 0)
                    break;
            }
        }
        return `${text.substring(0, truncatePoint)}...`;
    }
    static truncateTextStatic(text, limiter) {
        if (!text)
            return '';
        if (text.length <= limiter)
            return text;
        return `${text.substring(0, limiter)}...`;
    }
    static isGuidValid(input) {
        if (!input)
            return false;
        return !!input.match(this.regex.GUID);
    }
    static recognizeInlineUrl(text) {
        const pattern = this.regex[InlineLinkTypes.URL];
        const matches = text.matchAll(pattern);
        if (!matches)
            return [];
        const result = [];
        for (const match of matches) {
            const capturedMarkdownBrackets = match[1]; // Might be markdown brackets "]("
            if (!capturedMarkdownBrackets) {
                result.push(match[2]);
            }
        }
        return result;
    }
    static recognizeInlineEmail(text) {
        const pattern = this.regex[InlineLinkTypes.Email];
        const matches = text.matchAll(pattern);
        if (!matches)
            return [];
        const result = [];
        for (const match of matches) {
            const capturedMarkdownBrackets = match[1]; // Might be markdown brackets "](mailto:"
            if (!capturedMarkdownBrackets) {
                result.push(match[2]);
            }
        }
        return result;
    }
    static recognizeInlineLink(text, type) {
        var _a, _b;
        const pattern = this.regex[type];
        return (_b = (_a = text.match(pattern)) === null || _a === void 0 ? void 0 : _a.map((res) => res.trim())) !== null && _b !== void 0 ? _b : [];
    }
    static parseTemplate(source) {
        const parser = new DOMParser();
        return parser.parseFromString(source, 'text/html').body.firstChild;
    }
    static purifyHtml(source) {
        return DOMPurify.sanitize(source, {
            ALLOWED_ATTR: ['href', 'target', 'class', 'id', 'referrerpolicy', 'src'],
        });
    }
}
// Modified the regexs for URL and Email, so that markdown syntax is recognized
// (i.e. [description](https://link.com)) which always has a "](" before the actual link/email
Utilities.regex = {
    URL: /(\]\()?((http[s]?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|\bwww\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|\bhttps?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|\bwww\.[a-zA-Z0-9]+\.[^\s]{2,}))/gim,
    Email: /(\]\(mailto:)?((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/gim,
    Phone: /\(?\+?\(?([0-9]{2,3}|0)\)?[ ()]?([- ()]?\d[- ()]?){8,}/gim,
    GUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/gim,
};
