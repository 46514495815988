export class MediaAttachment {
    constructor(source, externalSettings) {
        this.preview = null;
        this.source = source;
        this.externalSettings = externalSettings;
    }
    applyExternalConfig() {
        if (!this.externalSettings)
            return;
    }
    createFullScreenContainer() {
        const container = document.createElement('div');
        container.id = 'lailo-media-display-fullscreen';
        const closeButton = document.createElement('button');
        closeButton.id = 'lailo-close-fullscreen-button';
        const icon = document.createElement('i');
        icon.className = 'fas fa-times';
        closeButton.appendChild(icon);
        const text = document.createElement('span');
        text.id = 'lailo-close-button-text';
        text.textContent = 'Close';
        closeButton.appendChild(text);
        closeButton.addEventListener('click', () => container.remove());
        container.appendChild(closeButton);
        return container;
    }
}
