import htmlTemplate from '../../templates/media/document.html';
import { Utilities } from '../../utilities/Utilities';
import { MediaAttachment } from './MediaAttachment';
export class DocumentAttachment extends MediaAttachment {
    constructor(source, externalSettings) {
        super(source, externalSettings);
        this.mainContainer = Utilities.parseTemplate(htmlTemplate.trim());
        this.queryElements();
        this.applyExternalConfig();
        this.attachEventListeners();
    }
    attachEventListeners() {
        this.mainContainer.addEventListener('click', (e) => {
            e.stopPropagation();
            this.link.click();
        });
    }
    attachSource() {
        const isPdf = this.source.contentType.includes('pdf');
        this.thumbnail.classList.add(isPdf ? 'fa-file-pdf' : 'fa-file-lines');
        this.title.textContent = Utilities.truncateTextStatic(this.source.name, 20);
        this.title.title = this.source.name;
        this.meta.textContent = this.source.contentType;
        // In Chrome-mobile?
        if (isPdf) {
            this.thumbnail.dataset.type = 'pdf';
            this.link.href = this.source.contentUrl;
            this.link.target = '_blank';
            return;
        }
        // Downloading
        const file = new Blob([this.source.contentUrl], { type: this.source.contentType });
        this.link.href = URL.createObjectURL(file);
        this.link.download = this.source.name;
    }
    queryElements() {
        this.link = this.mainContainer.querySelector('#lailo-document-link');
        this.thumbnail = this.mainContainer.querySelector('#lailo-document-thumbnail');
        this.title = this.mainContainer.querySelector('#lailo-document-title');
        this.meta = this.mainContainer.querySelector('#lailo-document-meta');
        this.attachSource();
    }
}
DocumentAttachment.COMMON_DOCUMENT_TYPES = [
    'pdf',
    'msword',
    'officedocument',
    'opendocument',
    'powerpoint',
    'rtf',
    'text/plain',
    'ms-excel',
];
