import htmlTemplate from '../../templates/media/image.html';
import { Utilities } from '../../utilities/Utilities';
import { MediaAttachment } from './MediaAttachment';
export class ImageAttachment extends MediaAttachment {
    constructor(source, externalSettings) {
        super(source, externalSettings);
        this.mainContainer = Utilities.parseTemplate(htmlTemplate.trim());
        this.setupFullscreenContainer();
        this.queryElements();
        this.attachEventListeners();
        this.applyExternalConfig();
    }
    attachEventListeners() {
        this.mainContainer.addEventListener('click', () => {
            document.body.prepend(this.fullscreenContainer);
        });
    }
    attachSource() {
        this.preview.style.visibility = 'hidden';
        this.preview.src = this.source.contentUrl;
        this.preview.onload = () => {
            this.preview.style.visibility = 'visible';
        };
        this.fullscreenContainer.style.backgroundImage = `url(${this.source.contentUrl})`;
    }
    queryElements() {
        this.preview = this.mainContainer.querySelector('#lailo-media-image');
        this.attachSource();
    }
    setupFullscreenContainer() {
        const container = this.createFullScreenContainer();
        container.addEventListener('click', () => container.remove());
        this.fullscreenContainer = container;
    }
}
