import markdownit from 'markdown-it';
import 'simplebar';
import TypeIt from 'typeit';
import { InlineLinkTypes } from '../architecture/enums/InlineLinks';
import { Languages } from '../architecture/enums/Languages';
import { errorMessageCollection } from '../common/errorMessageCollection';
import { Utilities } from '../utilities/Utilities';
import { LailoPrivacy } from './components/LailoPrivacy';
import { LailoAdaptiveCard } from './media/LailoAdaptiveCard';
import { MediaProcessor } from './media/MediaProcessor';
export class LailoBase {
    constructor({ botSecret, widgetType, language, texts, generalSettings, mediaSettings, colorSettings }) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        // Creating a bundle of functions that are tied to replacing inline clickable sources in the avatar's response
        // Emails, URLs and Phone numbers
        this.inlineLinkParsers = {
            replaceUrls: (text) => {
                const urls = Utilities.recognizeInlineUrl(text);
                urls.forEach((url) => {
                    text = text.replace(url, this.createInlineLink({
                        value: url,
                        type: InlineLinkTypes.URL,
                        target: this.generalSettings.openLinksInNewTab ? '_blank' : '_self',
                    }));
                });
                return text;
            },
            replaceEmails: (text) => {
                const emails = Utilities.recognizeInlineEmail(text);
                emails.forEach((email) => {
                    text = text.replace(email, this.createInlineLink({ value: email, type: InlineLinkTypes.Email }));
                });
                return text;
            },
        };
        // Must be overriden in derived classes if needed.
        // This associative array will contain the text contents for the character's html template.
        this.htmlTextContentCollection = {
            [Languages.German]: {
                userInputContainerTitle: 'Wie kann ich dir helfen?',
                askMeContainerTitle: ['Frag mich!', 'Danke'],
                userInputPlaceholder: ['Nachricht senden...', 'Ich höre zu...'],
                privacyContainer: {
                    title: 'Datenschutz',
                    description: 'Bitte lesen Sie sich unsere <a href="#">Datenschutz-</a> und <a href="#">Privatsphäreerklärung</a> durch.',
                    checkBoxLabel: 'Ich bin einverstanden.',
                    buttonLabel: 'Akzeptieren',
                },
            },
            [Languages.English]: {
                userInputContainerTitle: 'What can I help you with?',
                askMeContainerTitle: ['Ask me!', 'Thank you'],
                userInputPlaceholder: ['Send a message...', 'I am listening...'],
                privacyContainer: {
                    title: 'Privacy Declaration',
                    description: 'Please read our <a href="#">data protection</a> and <a href="#">privacy</a> statement.',
                    checkBoxLabel: 'I agree.',
                    buttonLabel: 'Accept',
                },
            },
            [Languages.French]: {
                userInputContainerTitle: "Comment puis-je t'aider?",
                askMeContainerTitle: ['Demande moi', 'Merci'],
                userInputPlaceholder: ['Envoye le message', "J'écoute..."],
                privacyContainer: {
                    title: 'Déclaration de confidentialité',
                    description: 'Veuillez lire notre déclaration <a href="#">de protection des données</a> et <a href="#">de confidentialité</a>.',
                    checkBoxLabel: "Je suis d'accord.",
                    buttonLabel: 'Consentement',
                },
            },
        };
        this.predefinedActions = {
            forceTextInput: {
                shouldTrigger: false,
                handler: () => this.forceTextInput(),
            },
            activateMicrophone: {
                shouldTrigger: false,
                handler: () => {
                    if (!window.character.speaking) {
                        this.onListenButtonClick();
                    }
                },
            },
        };
        this.recentlySentMessage = '';
        this.mainContainerId = 'lailo-smart-character';
        // Every version of Lailo needs a customized botStateCollection. This method must be overriden in derived classes.
        this.botStateCollection = {
            beforeBotInitialized: {
                applyState: () => { },
            },
            idle: {
                applyState: () => { },
            },
            listening: {
                applyState: () => { },
            },
            answering: {
                applyState: (data) => { },
            },
            error: {
                applyState: (err) => { },
            },
        };
        this.mobileInputIsOpened = false;
        this.isMobileDevice = false;
        /**
         * Filters out the CDN script tag from the DOM and replaces the JavaScript via the matching CSS.
         * This method makes it possible to migrate CDNs without having to touch this code.
         * @param widgetType type of the widget as string
         */
        this.createCSSSource = (widgetType) => {
            var _a;
            const cdnScriptSource = (_a = Array.from(document.querySelectorAll('script')).find((scriptElement) => scriptElement.src.includes('lailo/ai-avatar/libs'))) === null || _a === void 0 ? void 0 : _a.src;
            if (!cdnScriptSource)
                return;
            const urlFragments = cdnScriptSource.split('/');
            urlFragments[urlFragments.length - 1] = `${widgetType}.css`;
            return urlFragments.join('/');
        };
        /**
         * Dynamically displays either the send button (if there is a user input) or the microphone button
         */
        this.changeInputButtonOnUserInput = () => {
            if (this.userInput.value.length > 0 || this.predefinedActions.forceTextInput.shouldTrigger) {
                this.hideMicrophone();
            }
            else {
                this.showMicrophone();
            }
        };
        this.botSecret = botSecret;
        this.language = language !== null && language !== void 0 ? language : Languages.English;
        this.widgetType = widgetType;
        this.generalSettings = {
            openLinksInNewTab: (_a = generalSettings === null || generalSettings === void 0 ? void 0 : generalSettings.openLinksInNewTab) !== null && _a !== void 0 ? _a : true,
            privacyConsentNeeded: !!(generalSettings === null || generalSettings === void 0 ? void 0 : generalSettings.privacyConsentNeeded),
            initAvatar: (_b = generalSettings === null || generalSettings === void 0 ? void 0 : generalSettings.initAvatar) !== null && _b !== void 0 ? _b : true,
        };
        this.texts = {
            title: (_c = texts === null || texts === void 0 ? void 0 : texts.title) !== null && _c !== void 0 ? _c : this.htmlTextContentCollection[this.language].userInputContainerTitle,
            inputPlaceholder: (_d = texts === null || texts === void 0 ? void 0 : texts.inputPlaceholder) !== null && _d !== void 0 ? _d : this.htmlTextContentCollection[this.language].userInputPlaceholder[0],
            buttonTexts: {
                opened: (_f = (_e = texts === null || texts === void 0 ? void 0 : texts.buttonTexts) === null || _e === void 0 ? void 0 : _e.opened) !== null && _f !== void 0 ? _f : this.htmlTextContentCollection[this.language].askMeContainerTitle[1],
                closed: (_h = (_g = texts === null || texts === void 0 ? void 0 : texts.buttonTexts) === null || _g === void 0 ? void 0 : _g.closed) !== null && _h !== void 0 ? _h : this.htmlTextContentCollection[this.language].askMeContainerTitle[0],
            },
            privacy: {
                title: (_k = (_j = texts === null || texts === void 0 ? void 0 : texts.privacy) === null || _j === void 0 ? void 0 : _j.title) !== null && _k !== void 0 ? _k : this.htmlTextContentCollection[this.language].privacyContainer.title,
                checkBoxLabel: (_m = (_l = texts === null || texts === void 0 ? void 0 : texts.privacy) === null || _l === void 0 ? void 0 : _l.checkBoxLabel) !== null && _m !== void 0 ? _m : this.htmlTextContentCollection[this.language].privacyContainer.checkBoxLabel,
                buttonLabel: (_p = (_o = texts === null || texts === void 0 ? void 0 : texts.privacy) === null || _o === void 0 ? void 0 : _o.buttonLabel) !== null && _p !== void 0 ? _p : this.htmlTextContentCollection[this.language].privacyContainer.buttonLabel,
                description: ((_q = texts === null || texts === void 0 ? void 0 : texts.privacy) === null || _q === void 0 ? void 0 : _q.description) ? texts.privacy.description
                    : this.htmlTextContentCollection[this.language].privacyContainer.description,
            },
            exampleQuestions: (_r = texts === null || texts === void 0 ? void 0 : texts.exampleQuestions) !== null && _r !== void 0 ? _r : this.setDefaultExampleQuestions(this.language),
        };
        this.mediaSettings = mediaSettings !== null && mediaSettings !== void 0 ? mediaSettings : {};
        this.head = document.querySelector('head');
        this.isListening = false;
        this.isInputPanelOpen = false;
        this.injectDependencies();
        this.setColorSchema(colorSettings);
        // These elements are the bare minimum for the simplest widget (skeleton) to function.
        // They will be injected in the overriden method in LailoSkeleton
        this.loader = document.getElementById('lailo-loading');
        this.inputForm = document.getElementById('lailo-form');
        this.micIcon = document.getElementById('lailo-microphone-icon');
        this.micBtn = document.getElementById('lailo-microphone-button');
        this.stopBtn = document.getElementById('lailo-stop-button');
        this.userInput = document.getElementById('lailo-user-input');
        this.sendTextIcon = document.getElementById('lailo-send-text-icon');
        this.sendTextButton = document.getElementById('lailo-send-text-button');
        this.askMeContainer = document.getElementById('lailo-ask-me-container');
        this.typeItContainer = document.getElementById('lailo-example-questions');
        this.characterContainer = document.getElementById('lailo-character-canvas');
        this.botOutputTextField = document.getElementById('lailo-answer-text-field');
        this.userInputContainer = document.getElementById('lailo-user-input-container');
        this.userControlGroup = this.userInputContainer.querySelector('.lailo-control-group');
        this.botIntegrationContainer = document.getElementById('lailo-widget-container');
        this.hideUserInputContainerBtn = document.getElementById('lailo-widget-toggle-button');
        this.userInputContainerTitle = document.getElementById('lailo-user-input-container-title');
        this.mobileInputTypeItContainer = document.getElementById('lailo-mobile-input-example-questions');
        this.mobileInputForm = document.getElementById('lailo-mobile-form');
        this.mobileUserInput = document.getElementById('lailo-mobile-input');
        this.mainContainer = document.getElementById(this.mainContainerId);
        this.mobileBackButton = document.getElementById('lailo-mobile-back-button');
        if (this.texts.exampleQuestions.length > 0) {
            this.initTypeIt();
        }
        this.privacy = new LailoPrivacy(this.userControlGroup, this.texts.privacy, !!this.generalSettings.privacyConsentNeeded, () => {
            this.userInputContainerTitle.dataset.lailoHidden = 'true';
            this.typeit.freeze();
            this.typeItContainer.style.display = 'none';
        });
        // this.privacy.disableContainer(this.userControlGroup)
        this.privacy.subscribe('onUnmount', () => {
            this.userInputContainerTitle.dataset.lailoHidden = 'false';
            this.typeItContainer.style.display = 'block';
            this.typeit.unfreeze();
        });
        if (!this.generalSettings.initAvatar) {
            this.botIntegrationContainer.classList.add('avatar-hidden');
        }
        // End of constructor
    }
    get activeTriggers() {
        return Object.values(this.predefinedActions).filter((trigger) => trigger.shouldTrigger);
    }
    /**
     * Injects all the dependencies in the base classes's constructor.
     * The character's HTML Template, its CSS Template and Font Awesome will be added to the DOM.
     * Every version of the character needs these dependencies to operate properly.
     */
    injectDependencies() {
        this.injectHtmlTemplate();
        this.injectCssTemplate(this.widgetType);
        this.injectFontAwesome();
    }
    /**
     * Dynamically injects the link tag of the character's CSS into the head.
     * @param widgetType Type of the character. "fullScreenWidget" | "tinyWidget" | "halfScreenWidget"
     */
    injectCssTemplate(widgetType) {
        const lailoStyleSheet = document.getElementById('lailo-stylesheet');
        if (lailoStyleSheet) {
            lailoStyleSheet.remove();
        }
        const cssSource = this.createCSSSource(widgetType);
        const css = document.createElement('link');
        css.setAttribute('rel', 'stylesheet');
        css.setAttribute('type', 'text/css');
        css.setAttribute('id', `lailo-stylesheet`);
        css.setAttribute('href', cssSource);
        const simpleBarCss = document.createElement('link');
        simpleBarCss.rel = 'stylesheet';
        simpleBarCss.href = 'https://cdn.lorent-online.net/lailo/ai-avatar/assets/simplebar/simplebar.css';
        simpleBarCss.integrity = 'sha384-ygCRjo5PuB2wXMcaFVhoILj0PAzFKbtybp3MIcD31oCXzcQCi0Dbj2LH8fQ1f+Eb';
        simpleBarCss.crossOrigin = 'anonymous';
        this.head.prepend(css);
        this.head.appendChild(simpleBarCss);
    }
    getColorSchema() {
        return {
            primary: getComputedStyle(document.documentElement).getPropertyValue('--lailo-primary-color'),
            primaryLight: getComputedStyle(document.documentElement).getPropertyValue('--lailo-primary-color-light'),
            secondary: getComputedStyle(document.documentElement).getPropertyValue('--lailo-secondary-color'),
            primaryText: getComputedStyle(document.documentElement).getPropertyValue('--lailo-text-color'),
            secondaryText: getComputedStyle(document.documentElement).getPropertyValue('--lailo-secondary-text-color'),
            inputFieldBackground: getComputedStyle(document.documentElement).getPropertyValue('--lailo-input-field-bg'),
            inputFieldText: getComputedStyle(document.documentElement).getPropertyValue('--lailo-input-text-color'),
            disabledBackground: getComputedStyle(document.documentElement).getPropertyValue('--lailo-disabled-bg-color'),
            background: getComputedStyle(document.documentElement).getPropertyValue('--lailo-sidebar-bg-color'),
        };
    }
    setColorSchema(userColors) {
        if (!userColors)
            return;
        const settings = Object.assign(Object.assign({}, this.getColorSchema()), userColors);
        document.documentElement.style.setProperty('--lailo-primary-color', settings.primary);
        document.documentElement.style.setProperty('--lailo-primary-color-light', settings.primaryLight);
        document.documentElement.style.setProperty('--lailo-secondary-color', settings.secondary);
        document.documentElement.style.setProperty('--lailo-text-color', settings.primaryText);
        document.documentElement.style.setProperty('--lailo-secondary-text-color', settings.secondaryText);
        document.documentElement.style.setProperty('--lailo-input-field-bg', settings.inputFieldBackground);
        document.documentElement.style.setProperty('--lailo-input-text-color', settings.inputFieldText);
        document.documentElement.style.setProperty('--lailo-disabled-bg-color', settings.disabledBackground);
        document.documentElement.style.setProperty('--lailo-sidebar-bg-color', settings.background);
    }
    /**
     * Injects the Font Awesome CDN to the document's head. Font Awesome is one of the character's dependencies and will be dynamically injected into the DOM.
     */
    injectFontAwesome() {
        const firstLinkTagInHead = document.querySelector('head link');
        const fontAwesomeCss = document.createElement('link');
        fontAwesomeCss.setAttribute('rel', 'stylesheet');
        fontAwesomeCss.setAttribute('href', 'https://cdn.lorent-online.net/lailo/ai-avatar/assets/fa/css/all.css');
        fontAwesomeCss.setAttribute('integrity', 'sha384-HzLeBuhoNPvSl5KYnjx0BT+WB0QEEqLprO+NBkkk5gbc67FTaL7XIGa2w1L0Xbgc');
        fontAwesomeCss.setAttribute('crossorigin', 'anonymous');
        this.head.insertBefore(fontAwesomeCss, firstLinkTagInHead);
    }
    /**
     * Initializing a TypeIt object using the exampleQuestions
     */
    initTypeIt() {
        this.typeit = new TypeIt(`#${this.typeItContainer.id}`, {
            strings: this.texts.exampleQuestions,
            lifeLike: true,
            loop: true,
            waitUntilVisible: false,
            breakLines: false,
        }).go();
        this.mobileInputTypeItContainer = new TypeIt(`#${this.mobileInputTypeItContainer.id}`, {
            strings: this.texts.exampleQuestions,
            lifeLike: true,
            loop: true,
            waitUntilVisible: false,
            breakLines: false,
        }).go();
        this.mobileInputTypeItContainer.freeze();
    }
    /**
     * Populates the TypeIt string array depending on the provided language if no initial value has been set.
     * @param language The language code e.g. 'de-DE'. This parameter comes from the initialization object which must be created on the Frontend.
     */
    setDefaultExampleQuestions(language) {
        switch (language) {
            case Languages.German:
                return ['z.B. Wer bist du?', 'z.B. Was kannst du für mich tun?', 'Frag mich einfach!'];
            case Languages.French:
                return ['p.ex. Qui est-tu?', 'p.ex. Que peux-tu faire pour moi?', 'Demandes-moi quelque chose!'];
            case Languages.English:
            default:
                return ['e.g. Who are you?', 'e.g. What can you do for me?', 'Just ask me!'];
        }
    }
    /**
     * Sets the text content of each html element that had been declared in the htmlTextContentCollection object.
     * This method must be overridden and adjusted to the derived class's html template structure.
     */
    setHtmlTextContents() {
        if (this.userInputContainerTitle.textContent === '') {
            this.userInputContainerTitle.textContent = this.texts.title;
        }
        this.setPlaceholderText(this.texts.inputPlaceholder);
    }
    /**
     * Every version of Lailo needs customized event listeners.
     * This method must be overridden and adjusted to the derived class's html template structure.
     * Don't forget to call super().addEventListeners though =)
     */
    addEventListeners() {
        this.micBtn.addEventListener('click', () => this.onListenButtonClick());
        this.stopBtn.addEventListener('click', () => this.interruptAvatarActions());
        this.sendTextButton.addEventListener('click', () => this.onSendButtonClick());
        this.userInput.addEventListener('keypress', (e) => this.onEnterPressed(e));
        this.mobileUserInput.addEventListener('keypress', (e) => this.onEnterPressed(e));
        const isMobileDeviceHandler = () => {
            console.log('Lailo Widget will use Mobile Input .');
            this.isMobileDevice = true;
            this.userInput.removeEventListener('touchstart', isMobileDeviceHandler);
        };
        this.userInput.addEventListener('touchstart', isMobileDeviceHandler, { passive: true });
        this.userInput.addEventListener('focusin', () => {
            if (this.isMobileDevice) {
                this.showMobileInput();
            }
            if (window.character.isSpeaking) {
                this.clearUserInputField();
            }
        });
        this.mobileBackButton.addEventListener('click', () => this.hideMobileInput());
        this.askMeContainer.addEventListener('click', () => this.toggleBotIntegrationContainer());
        this.inputForm.addEventListener('submit', (e) => e.preventDefault());
        this.mobileInputForm.addEventListener('submit', (e) => e.preventDefault());
    }
    showMobileInput() {
        this.mobileInputTypeItContainer.unfreeze();
        this.mainContainer.classList.add('show-mobile-input');
        this.mobileUserInput.focus();
        this.mobileInputIsOpened = true;
        // Workaround to prevent iOS from scolling input field out of sight.
        setTimeout(() => this.mobileUserInput.focus(), 100);
    }
    hideMobileInput() {
        this.mobileUserInput.value = '';
        this.mainContainer.classList.remove('show-mobile-input');
        this.mobileInputIsOpened = false;
        this.mobileInputTypeItContainer.freeze();
    }
    /**
     * Toggling the "lailo-container-show" class on the botIntegrationContainer.
     */
    toggleBotIntegrationContainer() {
        if ((window.character.isSpeaking || this.isListening) && this.isInputPanelOpen) {
            window.character.interrupt();
            this.isListening = false;
        }
        if (this.isInputPanelOpen) {
            this.clearBotOutputTextField();
            this.clearUserInputField();
        }
        this.setBotState('idle');
        this.botIntegrationContainer.classList.toggle('lailo-container-show');
        this.isInputPanelOpen = this.botIntegrationContainer.classList.contains('lailo-container-show');
    }
    openUserInputPanel() {
        if (this.isInputPanelOpen)
            return;
        this.toggleBotIntegrationContainer();
    }
    clearBotOutputTextField() {
        this.botOutputTextField.innerHTML = '';
    }
    isAnswerDisplayed() {
        return this.botOutputTextField.innerHTML !== '';
    }
    setPlaceholderText(text) {
        this.userInput.placeholder = text;
        this.mobileUserInput.placeholder = text;
    }
    /**
     * Hides the TypeItJS Container if the Avatar's answer is being displayed.
     * If there is no answer the example questions will be shown again.
     */
    toggleExampleQuestions() {
        if (window.character.isSpeaking || this.isAnswerDisplayed()) {
            this.typeItContainer.classList.add('lailo-hidden');
        }
        else {
            this.typeItContainer.classList.remove('lailo-hidden');
        }
    }
    /**
     * Sets the model's state according to stages of user interaction.
     * @param state "beforeBotInitialized" | "idle" | "listening" | "answering" | "error"
     * @param data IBotResponse object which will be used in "answering" and "error" states
     */
    setBotState(state, data) {
        const stateAction = this.botStateCollection[state];
        stateAction.applyState(data);
    }
    /**
     * Sets the user input's value to nothing (resetting the field)
     */
    clearUserInputField() {
        this.userInput.value = '';
        this.recentlySentMessage = '';
    }
    /**Sets the value of the user input. It will be important if the user used the microphone to communicate with the character to show what the character understood.
     */
    setUserInputContent(text) {
        this.userInput.value = text;
        this.recentlySentMessage = text;
    }
    /**
     * Creates a simple HTML paragraph element which will be appended to the botOutputTextField in the derived classes.
     * @param text Text content of the paragraph
     */
    createParagraph(text) {
        const p = document.createElement('p');
        p.dataset.simplebar = 'true';
        p.style.zIndex = '10';
        p.innerHTML = Utilities.purifyHtml(`<span class='lailo-answer-text-fragment'>${text}</span>`);
        p.id = 'lailo-answer-text';
        p.classList.add('lailo-answer-text');
        return p;
    }
    /**
     * Creates a simple HTML paragraph element which will be appended to the botOutputTextField in the derived classes.
     * @param text Text content of the paragraph
     */
    createParagraphForMarkdown(innerHtml) {
        const p = document.createElement('p');
        if (this.isMobileDevice) {
            p.style.overflowY = 'auto';
        }
        else {
            p.dataset.simplebar = 'true';
        }
        p.style.zIndex = '10000';
        p.innerHTML = Utilities.purifyHtml(`<span class='lailo-answer-text-fragment'>${innerHtml}</span>`);
        p.id = 'lailo-answer-text';
        p.classList.add('lailo-answer-text');
        // Links should open in a new tab. If the link was created by markdown-it library (i.e. it has no target
        // attribute), they need to be modified for that.
        const links = p.getElementsByTagName('a');
        for (let i = 0; i < links.length; i++) {
            if (!links[i].hasAttribute('target')) {
                links[i].classList.add('lailo-inline-link');
                links[i].setAttribute('data-lailo-inline-link-type', 'URL');
                links[i].setAttribute('target', '_blank');
                links[i].setAttribute('referrerpolicy', 'noopener noreferrer');
                const innerText = links[i].innerText;
                links[i].innerHTML = `<span class='lailo-inline-link-text'>${innerText}</span>`;
            }
        }
        return p;
    }
    /**
     * Creates an HTML anchor element which will be displayed as a button to redirect the user to a new tab.
     * @param target The href attribute of the anchor tag
     * @param caption Text content of the anchor tag
     */
    createAnchor(target, caption) {
        const a = document.createElement('a');
        a.setAttribute('href', target);
        a.className = 'lailo-link';
        a.textContent = caption;
        a.referrerPolicy = 'noopener noreferrer';
        a.target = this.generalSettings.openLinksInNewTab ? '_blank' : '_self';
        return a;
    }
    /**
     * Calls the onSendButtonClick method.
     * @param e The event object
     */
    onEnterPressed(e) {
        if (e.key == 'Enter') {
            e.preventDefault();
            this.onSendButtonClick();
        }
    }
    /**
     * Checks the validity of the inserted text and calls the character's send method with the user's input
     */
    onSendButtonClick() {
        const userInputValue = this.userInput.value || this.mobileUserInput.value;
        if (userInputValue != '' && userInputValue.replace(/\s/g, '').length > 0) {
            this.interruptAvatarActions();
            this.userInput.blur();
            window.character.send(userInputValue);
            if (this.mobileInputIsOpened) {
                this.hideMobileInput();
            }
        }
    }
    hideMicrophone() {
        this.micBtn.classList.add('lailo-hidden');
        this.sendTextButton.classList.remove('lailo-hidden');
    }
    showMicrophone() {
        this.micBtn.classList.remove('lailo-hidden');
        this.sendTextButton.classList.add('lailo-hidden');
    }
    showStopButtonWhileAnswering() {
        this.micBtn.classList.add('lailo-hidden');
        this.stopBtn.classList.remove('lailo-hidden');
    }
    hideStopButton() {
        this.micBtn.classList.remove('lailo-hidden');
        this.stopBtn.classList.add('lailo-hidden');
    }
    /**
     * Uses the associative array of error messages according to the chosen language to find the matching error message to an error code.
     * @param errorCode Key of the error object which will be sent by the server if something goes wrong
     */
    findErrorMessage(errorCode) {
        var _a, _b;
        if (errorMessageCollection[this.language]) {
            return (_a = errorMessageCollection[this.language][errorCode]) !== null && _a !== void 0 ? _a : errorMessageCollection[this.language]['InvalidAction'];
        }
        else {
            return ((_b = errorMessageCollection[Languages.English][errorCode]) !== null && _b !== void 0 ? _b : errorMessageCollection[Languages.English]['InvalidAction']);
        }
    }
    /**
     * Calls the character's "listen" function.
     * Hides the microphone icon and displays a loading spinner until the listening state is initialized.
     * Cannot trigger if text input is forced.
     */
    onListenButtonClick() {
        if (this.predefinedActions.forceTextInput.shouldTrigger)
            return;
        if (!this.isListening) {
            this.isListening = true;
            this.interruptAvatarActions();
            this.triggerLoading();
            window.character.listen();
        }
    }
    /**
     * Interrupts the Avatar and sets him into Idle state.
     */
    interruptAvatarActions() {
        window.character.interrupt();
        this.setBotState('idle');
    }
    /**
     * Manually triggers the button loading spinner
     */
    triggerLoading() {
        var _a;
        this.micIcon.classList.add('lailo-hidden');
        (_a = this.loader) === null || _a === void 0 ? void 0 : _a.classList.remove('lailo-hidden');
    }
    /**
     * The character's response will be evaluated. In case of an IErrorBotResponse the "handleBotOutputIfError" method will be called and this method will be terminated.
     * Otherwise the ISuccessfulBotReponse object will be evaluated and the text will be displayed in the bot answer container.
     * If the Reponse object contains an "actions" array of objects, that array evaluated and processed by the handleActions method.
     * @param data IBotResponse object which can be an ISuccessfulBotResponse or an IErrorBotResponse
     * @param responseStatus "success" | "error"
     */
    processAnswer(data, responseStatus = 'success') {
        this.resetAnswerField();
        if (responseStatus === 'error') {
            this.handleBotOutputIfError(data);
            return;
        }
        // Handling successful responses
        const { text, actions, videos, images, documents, suggestedActions, adaptiveCards } = data;
        const parsedText = this.parseInlineLinks(text !== null && text !== void 0 ? text : '');
        const md = markdownit({
            html: true,
        });
        const outputHtml = md.render(parsedText);
        // const paragraph = this.createParagraph(parsedText);
        const paragraph = this.createParagraphForMarkdown(outputHtml);
        // Handling the actions if there are any
        this.handleActions(actions, paragraph);
        // Creating MediaAttachments for each possible media type
        new MediaProcessor(paragraph, [...images, ...videos, ...documents], this.mediaSettings);
        // If there are suggested actions in the bot response, we are dealing with a multiple-choice message
        this.createMultiChoiceOptions(suggestedActions, paragraph);
        // Paragraph set up, can be appended to the DOM
        this.botOutputTextField.appendChild(paragraph);
        // Creating Adaptive Cards and appending them to the paragraph
        // Handling card AFTER the paragraph is appended to access its width for responsive design purposes
        this.handleAdaptiveCards(adaptiveCards, paragraph);
        // If there is no text to speak for the Avatar, the "onAnswered" callback will not fire.
        // Manual handling is necessary.
        if (!text) {
            this.setBotState('idle');
        }
    }
    parseInlineLinks(text) {
        return Object.values(this.inlineLinkParsers).reduce((baseText, parser) => parser(baseText), text);
    }
    createInlineLink(config) {
        const typeDefinition = (() => {
            switch (config.type) {
                case InlineLinkTypes.Email:
                    return { href: `mailto:${config.value}` };
                case InlineLinkTypes.Phone:
                    return { href: `tel:${config.value}` };
                case InlineLinkTypes.URL:
                default:
                    const isProtocolMissing = !config.value.startsWith('http') && !config.value.startsWith('https');
                    return { href: `${isProtocolMissing ? '//' + config.value : config.value}` };
            }
        })();
        return `<a 
              class='lailo-inline-link' 
              data-lailo-inline-link-type=${config.type}
              ${config.target ? 'target=' + config.target : null}
              referrerpolicy='noopener noreferrer'
              href=${typeDefinition.href}>
                <span class='lailo-inline-link-text'>${config.value}</span>
            </a>`;
    }
    handleAdaptiveCards(adaptiveCards, paragraph) {
        if (Utilities.isEmpty(adaptiveCards))
            return;
        adaptiveCards.forEach((card) => new LailoAdaptiveCard(card, paragraph));
    }
    resetAnswerField() {
        if (this.multiChoiceBtnContainer) {
            this.multiChoiceBtnContainer.remove();
            this.multiChoiceBtnContainer = undefined;
        }
        this.botOutputTextField.style.color = 'grey';
        this.botOutputTextField.innerHTML = '';
    }
    /**
     * Checks the possible supported actions and calls a handler for each of them.
     * @param actions The entire actions object from the Avatar response
     * @param paragraph The container paragraph to which the urlRedirect anchor tag will be appended.
     */
    handleActions(actions, paragraph) {
        if (!actions || !Array.isArray(actions))
            return;
        const actionTypes = [...new Set(actions.map((action) => action.type))];
        actionTypes.forEach((action) => {
            switch (action) {
                case 'forceTextInput':
                    this.predefinedActions.forceTextInput.shouldTrigger = true;
                    break;
                case 'activateMicrophone':
                    this.predefinedActions.activateMicrophone.shouldTrigger = true;
                    break;
                case 'urlRedirect':
                    this.handleUrlRedirects(actions.filter((action) => action.type === 'urlRedirect'), paragraph);
                    break;
                default:
                    break;
            }
        });
    }
    resetPredefinedActions() {
        Object.values(this.predefinedActions).forEach((trigger) => (trigger.shouldTrigger = false));
    }
    executePredefinedActionHandlers() {
        this.activeTriggers.forEach((action) => action.handler());
    }
    /**
     * Activates the microphone by imitating a user-click on the microphone button.
     */
    activateMicrophone() {
        window.character.listen();
    }
    /**
     * Creates a grid of buttons to display the provided options of a multiple-choice question.
     * @param options An array of strings or numbers
     */
    createMultiChoiceOptions(options, paragraph) {
        if (!options)
            return;
        const btnContainer = document.createElement('div');
        btnContainer.id = 'lailo-multi-choice-btn-container';
        options.forEach((option) => {
            const btn = this.createMultiChoiceButton(option);
            btnContainer.appendChild(btn);
        });
        this.multiChoiceBtnContainer = btnContainer;
        paragraph.appendChild(btnContainer);
    }
    /**
     * Creates a button that displays and handles the provided option from the multi-choice question
     * @param option The option to be displayed on the button
     * @returns The entire button element including event handler
     */
    createMultiChoiceButton(option) {
        var _a;
        const btn = document.createElement('button');
        btn.className = 'lailo-btn lailo-multi-choice-btn';
        const text = (_a = option === null || option === void 0 ? void 0 : option.title) !== null && _a !== void 0 ? _a : option === null || option === void 0 ? void 0 : option.displayText;
        btn.innerText = text;
        btn.addEventListener('click', () => {
            this.userInput.value = text;
            window.character.send(text, option.value);
        });
        return btn;
    }
    /**
     * Appends urlRedirect anchor tags to a paragraph
     * @param actions Actions that have the type 'urlRedirect'
     * @param paragraph The container paragraph to which the urlRedirect anchor tag will be appended.
     */
    handleUrlRedirects(urlActions, paragraph) {
        urlActions.forEach((action) => {
            let anchorElement;
            if ('data' in action) {
                anchorElement = this.createAnchor(action.data.url, action.data.caption);
            }
            else {
                anchorElement = this.createAnchor(action.url, action.caption);
            }
            paragraph.appendChild(anchorElement);
        });
    }
    /**
     * If the action contains a type of 'forceTextInput' the user input panel will be opened and the user input field will be focused.
     */
    forceTextInput() {
        this.openUserInputPanel();
        this.hideMicrophone();
        this.userInput.focus({ preventScroll: true });
    }
    /**
     * A simple red paragraph will be created and appended to the botOutputTextField.
     * @param error IErrorBotResponse object which contains an errorCode property.
     */
    handleBotOutputIfError(error) {
        const paragraph = this.createParagraph(this.findErrorMessage(error.errorCode));
        const textSpan = paragraph.querySelector('span');
        textSpan.className = 'lailo-error-text';
        this.botOutputTextField.appendChild(paragraph);
        return paragraph;
    }
}
