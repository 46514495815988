import halfScreenWidgetTemplate from '../templates/halfScreenWidgetTemplate.html';
import { LailoTinyWidget } from './LailoTinyWidget';
export class LailoHalfScreenWidget extends LailoTinyWidget {
    constructor(settings) {
        super(settings);
        this.closeButton = document.getElementById('lailo-close-button');
        this.closeButton.addEventListener('click', () => this.toggleBotIntegrationContainer());
    }
    injectHtmlTemplate() {
        document.getElementById(this.mainContainerId).innerHTML = halfScreenWidgetTemplate.trim();
    }
    addEventListeners() {
        super.addEventListeners();
        window.addEventListener('resize', () => this.resizeBotResponsively());
    }
    /**
     * Resizes the character depending on the bot intgration container's state (shown/hidden)
     * Resizing will defer depending on the device's width
     */
    resizeBotIfUserInputHidden() {
        if (window.innerWidth > 1400)
            this.characterContainer.classList.add('lailo-hide');
        this.handleUserInputControlButtons();
        this.resizeBotResponsively();
        setTimeout(() => {
            this.characterContainer.classList.remove('lailo-hide');
        }, 400);
    }
    handleUserInputControlButtons() {
        if (this.isInputPanelOpen) {
            this.askMeContainer.style.display = 'none';
        }
        else {
            setTimeout(() => {
                if (!this.isInputPanelOpen)
                    this.askMeContainer.style.display = 'block';
            }, 400);
        }
    }
    resizeBotResponsively() {
        // Above tablet size
        if (window.innerWidth > 992) {
            if (this.isInputPanelOpen) {
                window.character.resize(880, 930);
            }
            else {
                window.character.resize(400, 375);
            }
        }
        else {
            // Mobile size
            window.character.resize(window.innerWidth * 0.9, window.innerHeight * 0.4);
        }
    }
}
