import fullScreenWidgetTemplate from '../templates/fullScreenWidgetTemplate.html';
import { Utilities } from '../utilities/Utilities';
import { LailoBase } from './LailoBase';
export class LailoFullScreenWidget extends LailoBase {
    constructor(settings) {
        super(settings);
        this.isPortrait = window.innerHeight >= window.innerWidth * 0.9;
        this.toggleInputPanelIcons = {
            closed: 'fas fa-pen',
            open: 'fas fa-times',
        };
        /**
         * Handles the character's size and camera adjustment depending on the input panel state.
         * Also dynamically changes the icon's of the hide-user-input-container-button
         */
        this.handleUserInputPanelStateChanges = () => {
            if (this.isPortrait) {
                this.changeAvatarViewOnInputPanelOpen();
                this.setMarginForCharacterCanvasOnPortraitOpen();
                this.positionCanvasOnPortraitOpen();
            }
            if (this.isInputPanelOpen) {
                this.hideUserInputContainerBtn.querySelector('#lailo-toggle-input-panel-icon').className =
                    this.toggleInputPanelIcons['open'];
            }
            else {
                this.hideUserInputContainerBtn.querySelector('#lailo-toggle-input-panel-icon').className =
                    this.toggleInputPanelIcons['closed'];
            }
        };
        /**
         * Resizes the avatar depending on the user input panel's state.
         * If closed, the character is in longshot mode and fills the screen
         * If open, the character is in medium mode and will be shrunk down
         */
        this.changeAvatarViewOnInputPanelOpen = () => {
            this.characterContainer.classList.add('lailo-hidden');
            const { height, width } = this.defaultSizeParams;
            let cameraAdjustment = 'medium';
            if (this.botIntegrationContainer.classList.contains('lailo-container-show')) {
                window.character.resize(width * 0.8, height * 0.7);
            }
            else {
                cameraAdjustment = 'longshot';
                window.character.resize(width, height);
            }
            window.character.adjustCamera(cameraAdjustment);
            setTimeout(() => {
                this.characterContainer.classList.remove('lailo-hidden');
            }, 550);
        };
        /**
         * Sets the canvas control group's user-question field to the asked question / statement
         * @param text The user input from talking or sending a message
         */
        this.setCanvasUserQuestionTextContent = (text) => {
            this.canvasUserQuestion.textContent = text;
        };
        /**
         * Clears the user question field in the canvas control group
         */
        this.clearUserQuestionField = () => {
            this.canvasUserQuestion.textContent = '';
        };
        // Setting the default size depending on the device's width
        this.defaultSizeParams = {
            height: window.innerHeight * 0.9,
            width: window.innerHeight * 0.8,
        };
        // Base template and dependencies will be injected by running the base class's constructor.
        this.loaders = document.querySelectorAll('.lailo-loading');
        this.canvasMicBtn = document.getElementById('lailo-canvas-microphone-button');
        this.canvasStopBtn = document.getElementById('lailo-canvas-stop-button');
        this.canvasUserQuestion = document.getElementById('lailo-canvas-user-question');
        this.canvasControlGroup = document.getElementById('lailo-canvas-control-group');
        this.userInputContainerTitle = document.getElementById('lailo-user-input-container-title');
        // Apply state is a function that handles the changes of different bot states by dynamically adding and removing css classes.
        this.botStateCollection = {
            beforeBotInitialized: {
                applyState: () => {
                    this.canvasMicBtn.classList.add('lailo-hidden');
                    this.loaders.forEach((loader) => loader.classList.remove('lailo-hidden'));
                },
            },
            idle: {
                applyState: () => {
                    this.loaders.forEach((loader) => loader.classList.add('lailo-hidden'));
                    this.micIcon.classList.remove('lailo-hidden');
                    this.canvasMicBtn.classList.remove('lailo-hidden');
                    this.canvasMicBtn.classList.remove('active');
                    this.canvasMicBtn.classList.remove('listening');
                    this.sendTextIcon.classList.remove('lailo-hidden');
                    this.botIntegrationContainer.classList.remove('before-init');
                    this.hideStopButton();
                    this.setHtmlTextContents();
                    if (this.userInput.value === this.recentlySentMessage) {
                        this.clearUserInputField();
                    }
                    this.clearUserQuestionField();
                    this.toggleExampleQuestions();
                },
            },
            listening: {
                applyState: () => {
                    var _a, _b;
                    this.canvasMicBtn.classList.add('listening');
                    this.loaders.forEach((loader) => loader.classList.add('lailo-hidden'));
                    this.micIcon.classList.remove('lailo-hidden');
                    this.sendTextIcon.classList.remove('lailo-hidden');
                    this.canvasMicBtn.classList.remove('lailo-hidden');
                    (_a = document.querySelector('.lailo-error-text')) === null || _a === void 0 ? void 0 : _a.remove();
                    this.hideStopButton();
                    this.setPlaceholderText(this.htmlTextContentCollection[this.language].userInputPlaceholder[1]);
                    this.canvasUserQuestion.textContent = this.htmlTextContentCollection[this.language].userInputPlaceholder[1];
                    (_b = document.querySelector('.lailo-error-text')) === null || _b === void 0 ? void 0 : _b.remove();
                },
            },
            answering: {
                applyState: (data) => {
                    this.loaders.forEach((loader) => loader.classList.add('lailo-hidden'));
                    this.sendTextIcon.classList.remove('lailo-hidden');
                    this.canvasMicBtn.classList.remove('listening');
                    this.toggleExampleQuestions();
                    this.showStopButtonWhileAnswering();
                    this.processAnswer(data);
                },
            },
            error: {
                applyState: (err) => {
                    this.processAnswer(err, 'error');
                    if (!this.botIntegrationContainer.classList.contains('lailo-container-show')) {
                        this.toggleBotIntegrationContainer();
                    }
                    if (err.interrupt) {
                        this.isListening = false;
                        this.botStateCollection['idle'].applyState(undefined);
                    }
                },
            },
        };
        this.setHtmlTextContents();
        this.addEventListeners();
        this.privacy.disableContainer(this.canvasControlGroup);
        this.privacy.subscribe('onUnmount', () => this.handleDynamicHTMLElementSize());
    }
    injectHtmlTemplate() {
        document.getElementById(this.mainContainerId).innerHTML = fullScreenWidgetTemplate.trim();
    }
    addEventListeners() {
        super.addEventListeners();
        if (this.isPortrait) {
            this.userInput.addEventListener('input', () => this.changeInputButtonOnUserInput());
        }
        this.canvasMicBtn.addEventListener('click', () => this.onListenButtonClicked());
        this.canvasStopBtn.addEventListener('click', () => this.interruptAvatarActions());
        window.addEventListener('resize', () => (this.isPortrait = window.innerHeight >= window.innerWidth));
    }
    toggleBotIntegrationContainer() {
        super.toggleBotIntegrationContainer();
        this.handleUserInputPanelStateChanges();
    }
    /**
     * Handles the dynamic resize of icons, buttons, input field and font sizes to keep the ratio of the HTML elements on all screen sizes
     */
    handleDynamicHTMLElementSize() {
        const closeBtnRect = this.askMeContainer.getBoundingClientRect();
        this.userInput.style.height = `${window.innerHeight * 0.06}px`;
        this.userInput.style.fontSize = `${window.innerHeight * 0.02}px`;
        this.userInput.style.paddingLeft = `${this.userInput.clientHeight + 10}px`;
        // These values depend on the userInput's height
        this.micBtn.style.width = `${this.userInput.clientHeight}px`;
        this.micBtn.style.height = `${this.userInput.clientHeight}px`;
        this.canvasMicBtn.style.width = `${this.userInput.clientHeight}px`;
        this.canvasMicBtn.style.height = `${this.userInput.clientHeight}px`;
        this.canvasStopBtn.style.width = `${this.userInput.clientHeight}px`;
        this.canvasStopBtn.style.height = `${this.userInput.clientHeight}px`;
        this.stopBtn.style.width = `${this.userInput.clientHeight + 2}px`;
        this.stopBtn.style.height = `${this.userInput.clientHeight + 2}px`;
        this.sendTextButton.style.width = `${this.userInput.clientHeight + 2}px`;
        this.sendTextButton.style.height = `${this.userInput.clientHeight + 2}px`;
        this.loaders.forEach((loader) => {
            loader.style.width = `${this.userInput.clientHeight * 0.8}px`;
            loader.style.height = `${this.userInput.clientHeight * 0.8}px`;
        });
        this.hideUserInputContainerBtn.style.width = `${window.innerHeight * 0.06}px`;
        this.hideUserInputContainerBtn.style.height = `${window.innerHeight * 0.06}px`;
        this.botOutputTextField.style.maxWidth = `${this.userInputContainer.clientWidth * 0.9}px`;
        // Font sizes
        this.typeItContainer.style.fontSize = `${window.innerHeight * 0.017}px`;
        this.botOutputTextField.style.fontSize = `${window.innerHeight * 0.02}px`;
        if (this.isPortrait) {
            this.userInputContainerTitle.style.fontSize = `${window.innerWidth * 0.05}px`;
            this.botOutputTextField.style.maxHeight = `${this.userInputContainer.clientHeight * 0.35}px`;
        }
        else {
            this.botOutputTextField.style.maxHeight = `${this.userInputContainer.clientHeight * 0.35 + closeBtnRect.top / 2}px`;
            this.userInputContainerTitle.style.fontSize = `${window.innerHeight * 0.03}px`;
        }
        // Icon sizes
        this.canvasMicBtn.style.fontSize = `${window.innerHeight * 0.05}px`;
        this.micBtn.style.fontSize = `${this.userInput.clientHeight * 0.65}px`;
        this.canvasStopBtn.style.fontSize = `${window.innerHeight * 0.05}px`;
        this.stopBtn.style.fontSize = `${this.userInput.clientHeight * 0.65}px`;
        this.sendTextButton.style.fontSize = `${this.userInput.clientHeight * 0.65}px`;
        this.hideUserInputContainerBtn.style.fontSize = `${window.innerHeight * 0.035}px`;
        this.botOutputTextField.style.paddingRight = `${this.hideUserInputContainerBtn.clientWidth / 1.5}px`;
        // Privacy Container
        if (this.privacy.isMounted) {
            if (this.isPortrait) {
                this.privacy.template.style.height = this.userInputContainer.clientHeight / 2.4 + 'px';
                this.privacy.description.style.maxHeight = this.privacy.template.clientHeight / 2.6 + 'px';
            }
            else {
                this.privacy.template.style.maxHeight = this.userInputContainer.clientHeight * 0.8 + 'px';
                this.privacy.description.style.maxHeight =
                    this.privacy.template.scrollHeight / 1.6 + closeBtnRect.top / 2 + 'px';
            }
        }
    }
    /**
     * Sets the margin-bottom of the user input container title to make place for the character canvas
     * which will be displayed between the title and the input field
     */
    setMarginForCharacterCanvasOnPortraitOpen() {
        const computedFontSize = +window.getComputedStyle(this.userInputContainerTitle, null).fontSize;
        this.userControlGroup.style.marginTop = `${this.characterContainer.clientHeight - computedFontSize}px`;
        this.userControlGroup.style.marginTop = `${this.characterContainer.clientHeight}px`;
    }
    /**
     * Calculates the position of the character canvas to be exactly on top of the input field
     * and below the title. The values must be calculated once the input panel is open and is on its final position, hence "transitionend"
     */
    positionCanvasOnPortraitOpen() {
        this.userInputContainer.addEventListener('transitionend', () => {
            const { top: canvasTop, bottom: canvasBottom } = this.characterContainer.getBoundingClientRect();
            const computedCanvasHeight = canvasBottom - canvasTop;
            const portraitBottomAnchor = this.privacy.isMounted ? this.privacy.template : this.userInput;
            const canvasTopPosition = `${portraitBottomAnchor.getBoundingClientRect().top - computedCanvasHeight + 7}px`;
            this.characterContainer.style.top = canvasTopPosition;
        });
    }
    /**
     * Handles the canvas microphone button and also calls the base classes onListenButtonClick instance method
     */
    onListenButtonClicked() {
        this.triggerLoading();
        this.onListenButtonClick();
    }
    showStopButtonWhileAnswering() {
        super.showStopButtonWhileAnswering();
        this.canvasMicBtn.classList.add('lailo-hidden');
        this.canvasStopBtn.classList.remove('lailo-hidden');
    }
    hideStopButton() {
        super.hideStopButton();
        this.canvasMicBtn.classList.remove('lailo-hidden');
        this.canvasStopBtn.classList.add('lailo-hidden');
    }
    triggerLoading() {
        super.triggerLoading();
        this.loaders.forEach((loader) => loader.classList.remove('lailo-hidden'));
        this.canvasMicBtn.classList.remove('listening');
        this.canvasMicBtn.classList.add('lailo-hidden');
        this.sendTextIcon.classList.add('lailo-hidden');
    }
    initialize() {
        return new Promise((resolve, reject) => {
            window.character.init({
                onInitialised: () => {
                    this.setBotState('idle');
                    const { height, width } = this.defaultSizeParams;
                    window.character.resize(width, height);
                    if (this.isPortrait)
                        this.botIntegrationContainer.classList.toggle('lailo-portrait-view');
                    this.handleDynamicHTMLElementSize();
                    resolve(window.character);
                },
                onQuestionAsked: (data) => {
                    var _a, _b;
                    this.isListening = false;
                    // If no answer was asked (via microphone) an empty object will be received with absolutely nothing in it
                    if (Utilities.isEmpty(data.message) && Utilities.isEmpty(data.data)) {
                        this.setBotState('idle');
                        return;
                    }
                    this.resetPredefinedActions();
                    this.triggerLoading();
                    this.setUserInputContent((_a = data.message) !== null && _a !== void 0 ? _a : '');
                    this.setCanvasUserQuestionTextContent((_b = data.message) !== null && _b !== void 0 ? _b : '');
                },
                onAnswered: () => {
                    this.executePredefinedActionHandlers();
                    this.setBotState('idle');
                },
                onAnswer: (data) => {
                    this.setBotState('answering', data);
                    const { images, videos, documents, adaptiveCards, suggestedActions } = data;
                    const hasAttachments = !Utilities.isEmpty(suggestedActions) ||
                        !Utilities.isEmpty(images) ||
                        !Utilities.isEmpty(videos) ||
                        !Utilities.isEmpty(documents) ||
                        !Utilities.isEmpty(adaptiveCards);
                    if (hasAttachments && !this.isInputPanelOpen) {
                        this.openUserInputPanel();
                    }
                },
                onError: (err) => {
                    this.setBotState('error', err);
                    reject(err);
                },
                onListening: () => {
                    this.setBotState('listening');
                },
            }, this.characterContainer, this.botSecret, this.language, this.generalSettings.privacyConsentNeeded, this.generalSettings.initAvatar);
        });
    }
}
