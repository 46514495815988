export const errorMessageCollection = {
    'de-DE': {
        CommunicationServerInitFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        NoCommunicationServerConnection: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        RefreshCommunicationServerTokenFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        CommunicationServerConnectionClosed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        DirectLineTokenFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        RefreshDirectLineTokenFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        DirectLineKeepAliveFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        LowBandwidth: 'Leider hast du gerade eine sehr schlechte Internetverbindung. Daher können einige meiner Funktionen stark eingeschränkt sein. Bitte versuche es doch später erneut, wenn deine Internetverbindung wieder besser ist.',
        BotCommunicationFailed: 'Leider kann ich dir auf Grund technischer Probleme gerade nicht weiterhelfen. Bitte stelle sicher, dass du mit dem Internet verbunden bist. Sollte dies der Fall sein, versuche es bitte später erneut.',
        InvalidCameraAdjustment: 'Leider habe ich gerade technische Probleme. Bitte versuche es später erneut.',
        InvalidCanvasSize: 'Leider habe ich gerade technische Probleme. Bitte versuche es später erneut.',
        MessageToBotEmpty: 'Oh, es scheint, als hättest du mir nichts mitgeteilt. Bitte sage oder schreibe mir etwas, damit ich dir weiterhelfen kann.',
        InvalidAction: 'Leider habe ich gerade technische Probleme. Bitte versuche es später erneut.',
        MicrophoneAccessDenied: 'Leider kann ich aktuell nicht auf dein Mikrofon zugreifen. Bitte stelle in deinen Browsereinstellungen sicher, dass ich auf dein Mikrofon zugreifen kann.',
        MicrophoneNotFound: 'Leider scheint es so, als hättest du kein Mikrofon. Bitte schließe ein Mikrofon an und versuche es anschließend erneut.',
        NoGetUserMediaAccess: 'Leider kann ich nicht auf dein Mikrofon zugreifen. Bitte stelle sicher, dass du über ein Mikrofon verfügst und dieses für den Zugriff durch deinen Browser freigegeben ist.',
        WebGLNotSupported: 'Leider wird WebGl von deinem Browser nicht unterstützt. Bitte versuche es mit einem anderen Browser',
        NoAttachmentsInActivity: 'Leider habe ich gerade technische Probleme. Bitte versuche es später erneut.',
    },
    'en-US': {
        CommunicationServerInitFailed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        NoCommunicationServerConnection: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        RefreshCommunicationServerTokenFailed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        CommunicationServerConnectionClosed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        DirectLineTokenFailed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        RefreshDirectLineTokenFailed: 'Bot offline',
        DirectLineKeepAliveFailed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        LowBandwidth: 'It seems like you have a bad internet connection at the moment. If the speech input is not working properly, you can still use the text input.',
        BotCommunicationFailed: "Unfortunately, I can't help you right now due to technical problems. Please make sure that you are connected to the internet. If this is the case, please try again later.",
        InvalidCameraAdjustment: "Unfortunately, I'm having technical problems right now. Please try again later.",
        InvalidCanvasSize: "Unfortunately, I'm having technical problems right now. Please try again later.",
        MessageToBotEmpty: "Oh, it seems you haven't told me anything. Please tell me or write something so I can help you.",
        InvalidAction: "Unfortunately, I'm having technical problems right now. Please try again later.",
        MicrophoneAccessDenied: 'Unfortunately, I cannot access your microphone at the moment. Please make sure in your browser settings that I can access your microphone.',
        MicrophoneNotFound: 'Unfortunately, it appears that you do not have a microphone. Please connect a microphone and try again afterwards.',
        NoGetUserMediaAccess: "Unfortunately, I can't access your microphone. Please make sure that you have a microphone and that it is enabled for access by your browser.",
        WebGLNotSupported: 'Unfortunately, WebGl is not supported by your browser. Please try another browser.',
        NoAttachmentsInActivity: "Unfortunately, I'm having technical problems right now. Please try again later.",
    },
    'fr-FR': {
        CommunicationServerInitFailed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        NoCommunicationServerConnection: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        RefreshCommunicationServerTokenFailed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        CommunicationServerConnectionClosed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        DirectLineTokenFailed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        RefreshDirectLineTokenFailed: 'Bot offline',
        DirectLineKeepAliveFailed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        LowBandwidth: 'Il semble que vous ayez une mauvaise connexion internet pour le moment. Si la saisie vocale ne fonctionne pas correctement, vous pouvez toujours utiliser la saisie de texte.',
        BotCommunicationFailed: "Malheureusement, je ne peux pas vous aider pour le moment en raison de problèmes techniques. Veuillez vous assurer que vous êtes connecté à l'internet. Si c'est le cas, veuillez réessayer plus tard.",
        InvalidCameraAdjustment: "Malheureusement, j'ai des problèmes techniques en ce moment. Veuillez réessayer plus tard.",
        InvalidCanvasSize: "Malheureusement, j'ai des problèmes techniques en ce moment. Veuillez réessayer plus tard.",
        MessageToBotEmpty: "Oh, il semble que vous ne m'ayez rien dit. S'il vous plaît, dites-moi ou écrivez quelque chose pour que je puisse vous aider.",
        InvalidAction: "Malheureusement, j'ai des problèmes techniques en ce moment. Veuillez réessayer plus tard.",
        MicrophoneAccessDenied: 'Malheureusement, je ne peux pas accéder à votre micro pour le moment. Veuillez vous assurer dans les paramètres de votre navigateur que je peux accéder à votre microphone.',
        MicrophoneNotFound: "Malheureusement, il semble que vous n'ayez pas de microphone. Veuillez brancher un microphone et réessayer ensuite.",
        NoGetUserMediaAccess: "Malheureusement, je ne peux pas accéder à votre micro. Veuillez vous assurer que vous avez un microphone et qu'il est activé pour l'accès par votre navigateur.",
        WebGLNotSupported: "Malheureusement, WebGl n'est pas pris en charge par votre navigateur. Veuillez essayer un autre navigateur.",
        NoAttachmentsInActivity: "Malheureusement, j'ai des problèmes techniques en ce moment. Veuillez réessayer plus tard.",
    }
};
