import { MediaAttachment } from './MediaAttachment';
import htmlTemplate from '../../templates/media/audio.html';
import { Utilities } from '../../utilities/Utilities';
export class AudioAttachment extends MediaAttachment {
    constructor(source, externalSettings) {
        super(source, externalSettings);
        this.mainContainer = Utilities.parseTemplate(htmlTemplate.trim());
        this.queryElements();
        this.attachEventListeners();
        this.applyExternalConfig();
    }
    attachEventListeners() {
        return;
    }
    attachSource() {
        return;
    }
    queryElements() {
        this.audio = this.mainContainer.querySelector('#lailo-audio-element');
        this.attachSource();
    }
}
