import tinyWidgetTemplate from '../templates/tinyWidgetTemplate.html';
import { Utilities } from '../utilities/Utilities';
import { LailoBase } from './LailoBase';
export class LailoTinyWidget extends LailoBase {
    constructor(settings) {
        super(settings);
        // Base template and dependencies will be injected by running the base class's constructor.
        // The overriden injectHtmlTemplate() takes care of the html structure that is needed by this particular widget.
        this.askMeContainerTitle = document.getElementById('lailo-ask-me-container-title');
        this.botStateCollection = {
            beforeBotInitialized: {
                applyState: () => {
                    this.micBtn.classList.add('lailo-hidden');
                    this.characterContainer.classList.add('lailo-hide');
                    this.hideUserInputContainerBtn.classList.add('lailo-hidden');
                    this.loader.classList.remove('lailo-hidden');
                },
            },
            idle: {
                applyState: () => {
                    this.loader.classList.add('lailo-hidden');
                    this.micBtn.classList.remove('lailo-hidden');
                    this.micBtn.classList.remove('active');
                    this.micBtn.classList.remove('listening');
                    this.micIcon.classList.remove('lailo-hidden');
                    this.characterContainer.classList.remove('lailo-hide');
                    this.botIntegrationContainer.classList.remove('before-init');
                    this.hideUserInputContainerBtn.classList.remove('lailo-hidden');
                    this.hideStopButton();
                    this.setHtmlTextContents();
                    if (this.userInput.value === this.recentlySentMessage) {
                        this.clearUserInputField();
                    }
                    this.toggleExampleQuestions();
                },
            },
            listening: {
                applyState: () => {
                    var _a;
                    this.loader.classList.add('lailo-hidden');
                    this.micBtn.classList.add('listening');
                    this.micIcon.classList.remove('lailo-hidden');
                    // Listening...
                    this.setPlaceholderText(this.htmlTextContentCollection[this.language].userInputPlaceholder[1]);
                    (_a = document.querySelector('.lailo-error-text')) === null || _a === void 0 ? void 0 : _a.remove();
                },
            },
            answering: {
                applyState: (data) => {
                    this.loader.classList.add('lailo-hidden');
                    this.micBtn.classList.remove('listening');
                    this.toggleExampleQuestions();
                    this.showStopButtonWhileAnswering();
                    this.processAnswer(data);
                },
            },
            error: {
                applyState: (err) => {
                    window.character.interrupt();
                    this.isListening = false;
                    this.processAnswer(err, 'error');
                    this.botStateCollection['idle'].applyState(undefined);
                },
            },
        };
        this.addEventListeners();
    }
    addEventListeners() {
        super.addEventListeners();
        window.addEventListener('resize', () => this.fitCharacterSizeToView());
        this.userInput.addEventListener('input', () => this.changeInputButtonOnUserInput());
    }
    /**
     * Injecting the widget's template
     */
    injectHtmlTemplate() {
        document.getElementById(this.mainContainerId).innerHTML = tinyWidgetTemplate.trim();
    }
    /**
     * Setting the title of the ask me container, the user input container and the placeholder of the user input
     * The chosen language (or English as fallback) will be used to filter out the matching textcontents from the htmlTextContentCollection object
     */
    setHtmlTextContents() {
        super.setHtmlTextContents();
        this.setAskMeContainerTitle();
    }
    /**
     * Dynamically changes the ask me container's title depending on the bot integration container's state (shown/hidden)
     */
    setAskMeContainerTitle() {
        this.askMeContainerTitle.textContent = this.isInputPanelOpen
            ? this.texts.buttonTexts.opened
            : this.texts.buttonTexts.closed;
    }
    /**
     * Calls the base classes prototype method. Converted into an instance method in the derived class to ensure the right context of "this"
     */
    toggleBotIntegrationContainer() {
        super.toggleBotIntegrationContainer();
        this.setAskMeContainerTitle();
        this.resizeBotIfUserInputHidden();
    }
    /**
     * Resizing the character if the botIntegrationContainer does not have the "lailo-container-show" class.
     * This prevents the weird transition when the character gets resized and relocated. And it just looks cool if he fades out- and in :)
     */
    resizeBotIfUserInputHidden() {
        this.characterContainer.classList.add('lailo-hide');
        this.fitCharacterSizeToView();
        setTimeout(() => {
            this.characterContainer.classList.remove('lailo-hide');
        }, 400);
    }
    fitCharacterSizeToView() {
        if (this.isInputPanelOpen && window.innerWidth > 600) {
            // Correct size for Avatar next to user input.
            window.character.resize(550, 685);
        }
        else {
            // Correct size for Avatar above user input
            window.character.resize(400, 355);
        }
    }
    initialize() {
        return new Promise((resolve, reject) => {
            window.character.init({
                onInitialised: () => {
                    var _a;
                    this.setBotState('idle');
                    window.character.adjustCamera('medium');
                    (_a = this.characterContainer.querySelector('div')) === null || _a === void 0 ? void 0 : _a.setAttribute('style', '');
                    this.resizeBotIfUserInputHidden();
                    resolve(window.character);
                },
                onQuestionAsked: (data) => {
                    var _a;
                    this.isListening = false;
                    // If no answer was asked (via microphone) an empty object will be received with absolutely nothing in it
                    if (Utilities.isEmpty(data.message) && Utilities.isEmpty(data.data)) {
                        this.setBotState('idle');
                        return;
                    }
                    this.resetPredefinedActions();
                    this.triggerLoading();
                    this.setUserInputContent((_a = data.message) !== null && _a !== void 0 ? _a : '');
                },
                onAnswered: () => {
                    this.executePredefinedActionHandlers();
                    this.setBotState('idle');
                },
                onAnswer: (data) => {
                    this.setBotState('answering', data);
                },
                onError: (err) => {
                    this.setBotState('error', err);
                    reject(err);
                },
                onListening: () => {
                    this.setBotState('listening');
                },
            }, this.characterContainer, this.botSecret, this.language, this.generalSettings.privacyConsentNeeded, this.generalSettings.initAvatar);
        });
    }
}
