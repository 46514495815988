import markdownit from 'markdown-it';
import { AdaptiveCard, ExecuteAction, OpenUrlAction, SubmitAction } from 'adaptivecards';
export class LailoAdaptiveCard {
    constructor(structure, container) {
        this.structure = structure;
        this.renderedCard = this.createRenderedCard(structure);
        this.setResponsiveDesign(container);
        container.appendChild(this.renderedCard);
    }
    setupActionHandling(action) {
        if (action instanceof SubmitAction || action instanceof ExecuteAction)
            return this.handleSubmitAction(action);
        if (action instanceof OpenUrlAction)
            return this.handleUrlAction(action);
    }
    handleUrlAction(action) {
        var _a;
        const a = document.createElement('a');
        a.href = (_a = action.url) !== null && _a !== void 0 ? _a : '#';
        a.target = '_blank';
        a.referrerPolicy = 'noopener noreferrer';
        a.click();
        a.remove();
    }
    handleSubmitAction(action) {
        var _a, _b, _c;
        action.isEnabled = false;
        (_a = this.renderedCard.querySelectorAll('.ac-selectable')) === null || _a === void 0 ? void 0 : _a.forEach((selectable) => {
            const casted = selectable;
            casted.dataset.lailoDisabled = 'true';
            casted.onclick = (e) => e.preventDefault();
        });
        (_b = this.renderedCard.querySelectorAll('.ac-input')) === null || _b === void 0 ? void 0 : _b.forEach((input) => {
            const casted = input;
            casted.dataset.lailoDisabled = 'true';
            casted.onfocus = (e) => e.preventDefault();
            casted.onclick = (e) => e.preventDefault();
        });
        (_c = this.renderedCard.querySelectorAll('#input-field label')) === null || _c === void 0 ? void 0 : _c.forEach((label) => {
            const casted = label;
            casted.onclick = (e) => e.preventDefault();
            casted.setAttribute('for', 'lailo-disabled');
        });
        window.character.send(null, action.data);
    }
    createRenderedCard(structure) {
        const adaptiveCard = new AdaptiveCard();
        AdaptiveCard.onProcessMarkdown = function (text, result) {
            result.outputHtml = markdownit().render(text);
            result.didProcess = true;
        };
        adaptiveCard.parse(structure);
        adaptiveCard.onExecuteAction = (action) => {
            this.setupActionHandling(action);
        };
        const renderedCard = adaptiveCard.render();
        return renderedCard;
    }
    setResponsiveDesign(container) {
        if (container.clientWidth <= 450) {
            this.renderedCard.classList.add('lailo-ac-small');
        }
    }
}
