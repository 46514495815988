import { Languages } from './architecture/enums/Languages';
import { WidgetTypes } from './architecture/enums/WidgetTypes';
import { LailoFullScreenWidget } from './models/LailoFullScreenWidget';
import { LailoHalfScreenWidget } from './models/LailoHalfScreenWidget';
import { LailoTinyWidget } from './models/LailoTinyWidget';
export const initTinyWidget = (config) => {
    const initializer = setupInitialization(config);
    const charJs = document.createElement('script');
    charJs.src = `https://cdn.lorent-online.net/lailo/ai-avatar/libs/${initializer.apiVersion}/character.js`;
    document.body.appendChild(charJs);
    return new Promise((resolve, reject) => {
        try {
            charJs.onload = () => {
                const lailo = new LailoTinyWidget(Object.assign(Object.assign({}, initializer), { widgetType: WidgetTypes.tinyWidget }));
                resolve(lailo.initialize());
            };
        }
        catch (error) {
            reject(error);
        }
    });
};
export const initHalfScreenWidget = (config) => {
    const initializer = setupInitialization(config);
    const charJs = document.createElement('script');
    charJs.src = `https://cdn.lorent-online.net/lailo/ai-avatar/libs/${initializer.apiVersion}/character.js`;
    document.body.appendChild(charJs);
    return new Promise((resolve, reject) => {
        try {
            charJs.onload = () => {
                const lailo = new LailoHalfScreenWidget(Object.assign(Object.assign({}, initializer), { widgetType: WidgetTypes.halfScreenWidget }));
                resolve(lailo.initialize());
            };
        }
        catch (error) {
            reject(error);
        }
    });
};
export const initFullScreenWidget = (config) => {
    const initializer = setupInitialization(config);
    const charJs = document.createElement('script');
    charJs.src = `https://cdn.lorent-online.net/lailo/ai-avatar/libs/${initializer.apiVersion}/character.js`;
    document.body.appendChild(charJs);
    return new Promise((resolve, reject) => {
        try {
            charJs.onload = () => {
                const lailo = new LailoFullScreenWidget(Object.assign(Object.assign({}, initializer), { widgetType: WidgetTypes.fullScreenWidget }));
                resolve(lailo.initialize());
            };
        }
        catch (error) {
            reject(error);
        }
    });
};
const setupInitialization = (config) => {
    /**
     * title, inputPlaceholder, buttonTexts: { opened, closed } and exampleQuestions used to be on the top-level in the IInitObject
     * All of them have been placed into the texts-object but to guarantee the backwards-compatibility
     * this method processes the config and fills the texts object considering the legacy attributes as well.
     * Also, set default values for the language and the api version.
     */
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return Object.assign(Object.assign({}, config), { language: config.language || Languages.English, apiVersion: config.apiVersion || 'v1', texts: {
            inputPlaceholder: ((_a = config.texts) === null || _a === void 0 ? void 0 : _a.inputPlaceholder) || config.inputPlaceholder || undefined,
            buttonTexts: {
                opened: ((_c = (_b = config.texts) === null || _b === void 0 ? void 0 : _b.buttonTexts) === null || _c === void 0 ? void 0 : _c.opened) || ((_d = config.buttonTexts) === null || _d === void 0 ? void 0 : _d.opened) || undefined,
                closed: ((_f = (_e = config.texts) === null || _e === void 0 ? void 0 : _e.buttonTexts) === null || _f === void 0 ? void 0 : _f.closed) || ((_g = config.buttonTexts) === null || _g === void 0 ? void 0 : _g.closed) || undefined,
            },
            title: ((_h = config.texts) === null || _h === void 0 ? void 0 : _h.title) || config.title || undefined,
            exampleQuestions: ((_j = config.texts) === null || _j === void 0 ? void 0 : _j.exampleQuestions) || config.exampleQuestions || undefined,
            privacy: {
                title: ((_l = (_k = config.texts) === null || _k === void 0 ? void 0 : _k.privacy) === null || _l === void 0 ? void 0 : _l.title) || undefined,
                description: ((_o = (_m = config.texts) === null || _m === void 0 ? void 0 : _m.privacy) === null || _o === void 0 ? void 0 : _o.description) || undefined,
                checkBoxLabel: ((_q = (_p = config.texts) === null || _p === void 0 ? void 0 : _p.privacy) === null || _q === void 0 ? void 0 : _q.checkBoxLabel) || undefined,
                buttonLabel: ((_s = (_r = config.texts) === null || _r === void 0 ? void 0 : _r.privacy) === null || _s === void 0 ? void 0 : _s.buttonLabel) || undefined,
            },
        } });
};
